import { Link } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

const PostCard = ({ node }) => (
  <>
    <article className="mx-3 md:mx-0 mb-6 md:h-28 md:rounded" key={node.fields.slug}>
      <Link to={node.fields.slug} className="w-full h-full md:flex hover:shadow-lg md:rounded-l">
        <div className="md:w-1/3 h-full">
          {node.frontmatter.img &&
            node.frontmatter.img.childImageSharp &&
            node.frontmatter.img.childImageSharp.fluid && (
              <Img
                className="h-full max-h-52 object-cover"
                key={node.frontmatter.img.childImageSharp.fluid.src}
                fluid={node.frontmatter.img.childImageSharp.fluid}
              />
            )}
        </div>
        <div className="md:w-2/3 p-3">
          <h2 className="text-base mb-1">{node.frontmatter.title}</h2>
          {/* <p className="mb-2">{node.excerpt}</p> */}
          <span className="text-xs">
            {node.frontmatter.date}&nbsp;&nbsp;—&nbsp;
          </span>
          <span className="text-xs">{node.timeToRead} minute read</span>
        </div>
      </Link>
    </article>
  </>
)

export default PostCard
