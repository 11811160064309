import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { kebabCase } from 'lodash'

import DefaultLayout from '../components/layout'
import SEO from '../components/seo'
import Sidebar from '../components/sidebar'
import TopNav from '../components/topNav'
import PostCard from '../components/postCard'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage - 1 === 1 ? '/' : '/' + (currentPage - 1).toString()
    const nextPage = '/' + (currentPage + 1).toString()
    const tags = data.tags.group
    const recommends = data.recommends

    return (
      <DefaultLayout>
        <SEO title={siteTitle} />
        <div className="">
          <div className="w-full">
            <div className="md:hidden flex justify-center py-2 pb-6">
              <TopNav />
            </div>
            <div className="md:mt-20">
              {posts.map(({ node }) => {
                return <PostCard node={node} />
              })}
            </div>
            <div className="mt-12">
              <nav role="pagination" className="mx-3 md:mx-0 mb-12">
                {isFirst && !isLast && (
                  <ul className="flex justify-end">
                    <button className="">
                      <Link to={nextPage} rel="next" className="older-posts hover:underline">
                        Next →
                      </Link>
                    </button>
                  </ul>
                )}
                {isLast && !isFirst && (
                  <ul className="flex justify-start">
                    <button className="">
                      <Link to={prevPage} rel="prev" className="newer-posts hover:underline">
                        ← Prev
                      </Link>
                    </button>
                  </ul>
                )}
                {!isFirst && !isLast && (
                  <ul className="flex justify-between">
                    <button className="">
                      <Link to={prevPage} rel="prev" className="newer-posts hover:underline">
                        ← Prev
                      </Link>
                    </button>
                    <button className="">
                      <Link to={nextPage} rel="next" className="older-posts hover:underline">
                        Next →
                      </Link>
                    </button>
                  </ul>
                )}
              </nav>
            </div>
          </div>
          <Sidebar recommends={recommends} tags={tags} />
        </div>
      </DefaultLayout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "YYYY, MMM DD")
            title
            description
            img {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
    tags: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    recommends: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { recommend: { eq: true } } }
      limit: 5
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "YYYY, MMM DD")
            title
            description
            img {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`
